import React from "react";
import data from "../../data/data.json";
const Backend = () => {
  const skillsList = data.skills.backend.map((skill) => (
    <div key={skill.name}>
      <div className="skills__group">
        <div className="skills__data">
          <i class="bx bx-badge-check"></i>
          <div>
            <h3 className="skills__name">{skill.name}</h3>
            <span className="skills__level">{skill.level}</span>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend Developer</h3>
      <div className="skills__box">{skillsList}</div>
    </div>
  );
};

export default Backend;
