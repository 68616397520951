import Divider from "./Divider";
const Education = ({institution, degree, time_duration, forward=true}) => {
  return (
    <div className="qualification__data">
      {!forward && <div></div>}
      {!forward && <Divider/>}
      <div>
        <h3 className="qualification__title">
          {institution}
        </h3>
        <span className="qualification__subtitle"> {degree}</span>
        <div className="qualification__calendar">
          <i className="uil uil-calendar-alt"></i>
          {time_duration}
        </div>
      </div>
      {forward && <Divider/>}
      {forward && <div></div>}
    </div>
  )
};

export default Education;