import React from "react";
import handImg from "../../assets/hand.svg";
import sendImg from "../../assets/send.svg";
const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        Yucheng Lin <img src={handImg} alt="handImg" />
      </h1>
      <h3 className="home__subtitle">Software Developer</h3>
      <p className="home__description">
        I'm creative and passionate CS new grad in Madison.
      </p>
      <a href="#contact" className="button button--flex">
        Say Hello &nbsp;
        <img src={sendImg} alt="sendImg" />
      </a>
    </div>
  );
};

export default Data;
